/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-types */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
// 引入全局Antd
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "animate.css";
// 引入全局css
import "./assets/scss/style.scss";
import "./assets/scss/index.scss";
import "@/static/css/index.scss";
// 引入图表（所有图标见 icon 目录下的 demo_index.html）
// import './assets/icon/iconfont.css'
// import { iconFontJS } from '@/assets/icon/iconfont.js'
// const IconFont = antIcons.createFromIconfontCN({
//   scriptUrl: iconFontJS, //掉用本地iconfont.js资源显示图标，最终方案
// })
// import SvgIcon from './components/SvgIcon.vue'
// import './assets/icon/iconfont.js'
import { createFromIconfontCN } from "@ant-design/icons-vue";
const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_4318946_oqf5b0xxlsh.js", // 在 iconfont.cn 上生成
});
// 引入 全局注册组件
import PublicComponent from "@/components/componentInstall";
import VueScrollTo from "vue-scrollto";
const app = createApp(App);
app.component("my-icon", MyIcon);
// app.component('SvgIcon', SvgIcon)
app.use(VueScrollTo);
app.use(PublicComponent);
app.use(dataV);
// app.use(animate)
app.use(Antd);
app.use(store);
app.use(router);
app.mount("#app");
// const debounce = (callback: Function, delay: number) => {
//   let tid: any;
//   return function () {
//     const ctx = self;
//     tid && clearTimeout(tid);
//     tid = setTimeout(() => {
//       cancelIdleCallback.apply(ctx, arguments);
//     }, delay);
//   };
// };
// export default ()=>{
//   const _=(window as any).ResizeObserver;
//   (window as any).ResizeObserver = class ResizeObserver extends _ {
//     constructor(callback:any){
//       callback = debounce(callback,20)
//       super(callback)
//     }
//   }
// }