<template>
  <div class="app">
    <router-view />
  </div>
</template>
<style>

</style>
<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
</style>
