import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/meeting-data',
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: '/seat',
        name: 'seat',
        component: () => import('@/views/seat/index.vue'),
      },
      {
        path: '/meeting-data',
        name: 'meeting',
        component: () => import('@/views/meetingData/index.vue'),
        meta: {
          noKeepAlive: true,
        },
      },
      {
        path: '/meeting-list',
        name: 'meeting-list',
        component: () => import('@/views/meetingList/index.vue'),
        meta: {
          noKeepAlive: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
